import React from "react"
import Helmet from "react-helmet"
import useSiteMetadata from "../../hooks/useSiteMetadata"

function SEO({ lang, meta, title, description, keywords, image }) {
  const siteMetadata = useSiteMetadata()
  const metaDescription = description || siteMetadata.description
  const metaTitle = title || siteMetadata.title
  const metaKeywords = keywords || siteMetadata.keywords
  const metaImage = image || siteMetadata.image

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={`%s`}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: metaTitle,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `keywords`,
          content: metaKeywords.join(","),
        },
        {
          name: `og:image`,
          content: metaImage,
        },
      ].concat(meta)}
    />
  )
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
}

export default SEO
